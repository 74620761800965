import React, { useEffect, useCallback, useState} from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import DrawerContextProvider from '../helpers/drawerContext';
import PopupContextProvider from '../helpers/popupContext';
import Layout from '../components/Layout';
import NotFoundComponent from '../components/NotFoundComponent/NotFoundComponent';

const LANDSCALE = '90';

const NotFound = () => {
  const [viewportMeta, setViewportMeta] = useState(
    'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0',
  );
  useEffect(() => navigate('/404'), []);

  const oriantationChangeHandler = () => {
    if (!window) return null;
    if (Math.abs(window.orientation) == LANDSCALE) {
      return setViewportMeta?.('viewport-fit=cover, maximum-scale=1, shrink-to-fit=no, user-scalable=0');
    }
    return setViewportMeta?.('width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0');
  };

  useEffect(() => {
    if (!window) return null;

    if (Math.abs(window.orientation) == LANDSCALE) {
      setViewportMeta?.('viewport-fit=cover, maximum-scale=1, shrink-to-fit=no, user-scalable=0');
    }

    window?.addEventListener('resize', oriantationChangeHandler);
  }, []);

  const getMetaArray = useCallback(() => {
    return [
      {
        name: 'viewport',
        content: viewportMeta,
      },
    ];
  }, [viewportMeta]);

  return (
    <DrawerContextProvider>
      <PopupContextProvider>
        <Helmet htmlAttributes={{ lang: 'en' }} title={404} meta={getMetaArray()} />
        <Layout isFixedHeader>
          <NotFoundComponent />
        </Layout>
      </PopupContextProvider>
    </DrawerContextProvider>
  );
};

export default NotFound;
