// extracted by mini-css-extract-plugin
export var bodyHighlight = "notfound-module--body-highlight--0d47b";
export var bodyWideLine = "notfound-module--body-wide-line--30dbe";
export var h1DisplayXlarge = "notfound-module--h1-display-xlarge--c5169";
export var h2DisplayLarge = "notfound-module--h2-display-large--00100";
export var h3DisplayMedium = "notfound-module--h3-display-medium--6e0ea";
export var h4DispalySmall = "notfound-module--h4-dispaly-small--0ed87";
export var h5Display = "notfound-module--h5-display--2881a";
export var h6DisplaySubhead = "notfound-module--h6-display-subhead--73d30";
export var typographyBodyDefault = "notfound-module--typography-body-default--4d957";
export var typographyTips = "notfound-module--typography-tips--e80cf";
export var unknowPage = "notfound-module--unknowPage--df872";
export var unknowPageContentText = "notfound-module--unknowPageContentText--f4abe";
export var unknowPageContentWrapper = "notfound-module--unknowPageContentWrapper--b7dce";
export var unknowPageImage = "notfound-module--unknowPageImage--3ab27";
export var unknowPageis = "notfound-module--unknowPageis--c4f0f";