import classNames from 'classnames';
import React from 'react';

import NotFoundImage from '../../assets/hg/svg/404.svg';
import * as notFoundStyles from './notfound.module.scss';

const NotFoundComponent = () => {
  const { GATSBY_BRAND } = process.env;

  return (
    <div
      className={classNames('grid', `${notFoundStyles.unknowPage}`, `${notFoundStyles[`unknowPage${GATSBY_BRAND}`]}`)}
    >
      <div
        className={classNames(
          'g-col-lg-6',
          'g-start-lg-4',
          'g-col-md-4',
          'g-start-md-2',
          'g-col-sm-2',
          'g-start-sm-1',
          `${notFoundStyles.unknowPageContentWrapper}`,
          `${notFoundStyles.unknowPageImage}`,
        )}
      >
        <img src={NotFoundImage} alt="not found" />
      </div>
      <div
        className={classNames(
          'g-col-lg-6',
          'g-start-lg-4',
          'g-col-md-4',
          'g-start-md-2',
          'g-col-sm-2',
          'g-start-sm-1',
          `${notFoundStyles.unknowPageContentWrapper}`,
        )}
      >
        <h3 className={classNames('h3-display-medium', notFoundStyles.unknowPageContentText)}>
          Sorry, there seems to be a problem
        </h3>
        <p className={classNames('body-wide-line')}>
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
      </div>
    </div>
  );
};

export default NotFoundComponent;
